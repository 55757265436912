
import * as React from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { useTheme } from '@mui/material/styles';
import { Grid, Container, Typography, } from '@mui/material';
// sections
import {
  AppWidgetSummary,

} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

export default function DashboardAppPage() {
  const theme = useTheme();
  const [width, setWidth] = React.useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);
const isMobile = width <= 768;

  return (
    <div>
      <Helmet>
        <title> Oraclesbroker | Preventivo </title>
      </Helmet>
      <Typography textAlign={'center'}>
      <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            {!isMobile ?<img src={"https://oraclesbroker.it/wp-content/uploads/2022/03/cropped-logo_800x450.png"} alt="Logo" /> : <img height={140} src={"https://oraclesbroker.it/wp-content/uploads/2022/03/cropped-logo_800x450.png"}  alt="Logo" />}
          </Grid>
        </Grid>
      </Typography>
      <Container style={{ alignSelf: 'center' }}  maxWidth="xl">
      <Typography textAlign={'center'} variant="h4" color={"primary"} sx={{ mb: 5 }}>
          La polizza online che ti fa vivere tutto il buono dell'assicurazione
        </Typography>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12} md={12}>
            <AppWidgetSummary title="" total={714000} icon={'ant-design:android-filled'} />
          </Grid>
        </Grid>
      </Container>
    </div>
  );
}
