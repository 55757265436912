// @mui
import * as React from 'react';
import PropTypes from 'prop-types';
import { Card,Button,} from '@mui/material';
import StepperPrev from './stepper';
// ----------------------------------------------------------------------

AppWidgetSummary.propTypes = {
  color: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
};

export default function AppWidgetSummary({ title, color = 'primary', sx }) {
  const [visiblePreventivo, setSisiblePreventivo] = React.useState(false);
  const [width, setWidth] = React.useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    window.addEventListener('resize', handleWindowSizeChange);
    return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
    }
}, []);

const isMobile = width <= 768;

return (
    <>
    <Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        bgcolor: (theme) => theme.palette[color].dark,
        ...sx,
      }}>
      <Button onClick={() => { setSisiblePreventivo(true) }} variant="contained" color="primary">FAI UN PREVENTIVO GRATUITO</Button>
      
    </Card>
    {visiblePreventivo&&!isMobile&&<Card
      sx={{
        py: 5,
        boxShadow: 0,
        textAlign: 'center',
        bgcolor: (theme) => theme.palette[color].dark,
        ...sx,
      }}>
      <StepperPrev/>
    </Card>}
    </>
  );
}
