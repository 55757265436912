import * as React from 'react';
import {FormControl,FormLabel,RadioGroup,FormControlLabel,Radio,TextField,Typography,Box,MenuItem,Checkbox,Grid,} from '@mui/material';


export default function formAssicurativo (props) {
    const requestToSend = props.requestToSend;
    const handleNuovaAssicurazione = props.handleNuovaAssicurazione;
    const handleDataDecorrenza = props.handleDataDecorrenza;
    const handleInfo = props.handleInfo;
    const handleCompagniaAttuale = props.handleCompagniaAttuale;
    const handleRca = props.handleRca;
    const handleRcaMassimale = props.handleRcaMassimale;
    const handleTutelaLegale = props.handleTutelaLegale;
    const handleInfortuniConducente = props.handleInfortuniConducente;
    const handleFurtoIncendio = props.handleFurtoIncendio;
    const handleCristalli = props.handleCristalli;
    const handleEventiNaturali = props.handleEventiNaturali;
    const handleAttiVandalici = props.handleAttiVandalici;
    const handleCollisione = props.handleCollisione;
    const handleKasko = props.handleKasko;
    const handleRinunciaRivalsa = props.handleRinunciaRivalsa;
    const handleImprevisti = props.handleImprevisti;
    const handleBonusProtetto = props.handleBonusProtetto;
    const handleDispositivoSatellitare = props.handleDispositivoSatellitare;
    const handleRiparazione = props.handleRiparazione;
    const handleSospensione = props.handleSospensione;
    const handleConsenso = props.handleConsenso;
    return (
        <>
            <FormLabel id="demo-row-radio-buttons-group-label">Dati Assicurativi</FormLabel>
            <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Typography variant='h4' sx={{ mt: 2, mb: 1, py: 1 ,textAlign: 'center',color:"#447484"}}>
                        Inserire Dati Assicurativi
                    </Typography>
                    <br/><br/>
                    <div>
                    <RadioGroup
                        row
                        style={{color:'white'}}
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="row-radio-buttons-group"
                        value={requestToSend?.nuovassicurazione}
                        onChange={(e) => {handleNuovaAssicurazione(e.target.value)}}
                    >
                        <FormControlLabel value="1" control={<Radio />} label="Assicurazione in Scadenza"/>
                        <FormControlLabel value="2" control={<Radio />} label="Nuova Assicurazione" />
                    </RadioGroup>
                    </div>
                    <br/>
                    <Box component="form" sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}>
                    <div>
                        <TextField type="date" value={requestToSend?.datadecorrenza} onChange={(e) => {handleDataDecorrenza(e.target.value)}} color="primary" focused id="outlined-basic" label="Data di Decorrenza" variant="outlined" helperText="Data di Decorrenza" />
                        <TextField value={requestToSend?.info} onChange={(e) => {handleInfo(e.target.value)}} color="primary" focused id="outlined-basic" label="Informazioni Aggiuntive" variant="outlined" helperText="Informazioni Aggiuntive" />
                        <TextField value={requestToSend?.compagniaattuale} onChange={(e) => {handleCompagniaAttuale(e.target.value)}} color="primary" focused id="outlined-basic" label="Compagnia Attuale" variant="outlined" helperText="Compagnia Attuale" />
                    </div>
                    </Box>
                </Box>
                <Typography variant='h4' sx={{ mt: 2, mb: 1, py: 1 ,textAlign: 'center',color:"#447484"}}>
                            Coperture Assicurative:
                </Typography>
                    <hr/><br/>
                    <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left"}} container spacing={2}>
                        <Grid item xs={6}>
                            <Checkbox checked={requestToSend?.rca} onChange={(e) => {
                                    handleRca(e.target.checked);
                                }} 
                                style={{textAlign:"left"}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >R.C.A. Responsabilità Civile Auto</strong>
                        </Grid>
                        <Grid item xs={6}>
                            {requestToSend?.rca && (
                                <TextField value={requestToSend?.rcamassimale} onChange={(e) => {handleRcaMassimale(e.target.value)}} select color="primary" focused id="outlined-basic" label="Massimale" variant="outlined" helperText="Massimale" >
                                    <MenuItem key={"1"} value={"1"}>
                                        7.750.000,00€
                                    </MenuItem>
                                    <MenuItem key={"2"} value={"2"}>
                                        8.000.000,00€
                                    </MenuItem>
                                    <MenuItem key={"3"} value={"3"}>
                                        10.000.000,00€
                                    </MenuItem>
                                    <MenuItem key={"4"} value={"4"}>
                                        15.000.000,00€
                                    </MenuItem>
                                    <MenuItem key={"5"} value={"5"}>
                                        25.000.000,00€
                                    </MenuItem>
                                    <MenuItem key={"6"} value={"6"}>
                                        50.000.000,00€
                                    </MenuItem>
                                </TextField>
                            )}
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
                    <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left"}} container spacing={2}>
                        <Grid  item xs={6}>
                            <Checkbox checked={requestToSend?.tutelalegale} onChange={(e) => {handleTutelaLegale(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Tutela Legale</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={requestToSend?.infortuniconducente} onChange={(e) => {handleInfortuniConducente(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Infortuni del Conducente</strong>
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
                    <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left"}} container spacing={2}>
                        <Grid  item xs={6}>
                            <Checkbox checked={requestToSend?.furtoincendio} onChange={(e) => {handleFurtoIncendio(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Furto e Incendio</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={requestToSend?.cristalli} onChange={(e) => {handleCristalli(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Cristalli</strong>
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
                    <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left"}} container spacing={2}>
                        <Grid  item xs={6}>
                            <Checkbox checked={requestToSend?.eventinaturali} onChange={(e) => {handleEventiNaturali(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Eventi Naturali</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={requestToSend?.attivandalici} onChange={(e) => {handleAttiVandalici(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Atti Vandalici</strong>
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
                    <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left"}} container spacing={2}>
                        <Grid  item xs={6}>
                            <Checkbox checked={requestToSend?.collisione} onChange={(e) => {handleCollisione(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Collisione</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={requestToSend?.kasko} onChange={(e) => {handleKasko(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >KASKO Completa</strong>
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
                <Typography variant='h4' sx={{ mt: 2, mb: 1, py: 1 ,textAlign: 'center',color:"#447484"}}>
                    Ulteriori Coperture Extra
                </Typography>
                    <br/><hr/><br/>
                    <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left"}} container spacing={2}>
                        <Grid  item xs={6}>
                            <Checkbox checked={requestToSend?.rinunciarivalsa} onChange={(e) => {handleRinunciaRivalsa(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Rinuncie alle Rivalse</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={requestToSend?.imprevisti} onChange={(e) => {handleImprevisti(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Imprevisti e Complementari</strong>
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
                <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left"}} container spacing={2}>
                        <Grid  item xs={6}>
                            <Checkbox checked={requestToSend?.bonusprotetto} onChange={(e) => {handleBonusProtetto(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Bonus Protetto</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={requestToSend?.dispositivosatellitare} onChange={(e) => {handleDispositivoSatellitare(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Dispositivo Satellitare (Formula Telematica)</strong>
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
                <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left"}} container spacing={2}>
                        <Grid  item xs={6}>
                            <Checkbox checked={requestToSend?.riparazione} onChange={(e) => {handleRiparazione(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Riparazione in Carrozzerie Convenzionate</strong>
                        </Grid>
                        <Grid item xs={6}>
                            <Checkbox checked={requestToSend?.sospensione} onChange={(e) => {handleSospensione(e.target.checked)}} sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Sospensione</strong>
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
                <Box component="form" sx={{
                    '& .MuiTextField-root': { m: 1, width: '30ch' },}}>
                    <Grid style={{textAlign:"left",}} container spacing={2}>
                        <Grid item xs={12}>
                            <Checkbox checked={requestToSend?.consenso} onChange={(e) => {handleConsenso(e.target.checked)}}  sx={{ '& .MuiSvgIcon-root': { fontSize: 35 } }} /> <strong >Acconsento all'utilizzo delle informazioni e dei dati forniti per consentire ad Oracles (Broker Assicurativo) di proporre contratti coerenti con le richieste ed esigenze di copertura assicurative del Contraente stesso o dell’Assicurato. Inoltre incarico di ricercare sul mercato assicurativo le migliori condizioni assicurative, contrattuali ed economiche per la copertura della trattativa in oggetto, tramite l’analisi dei prodotti e delle compagnie presenti sul mercato assicurativo italiano. </strong>
                        </Grid>
                    </Grid>      
                </Box>
                <hr/><br/>
        </>
      );
}
