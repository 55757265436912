import * as React from 'react';
import {FormControl,FormLabel,RadioGroup,FormControlLabel,Radio,TextField,Typography,Box,MenuItem} from '@mui/material';

export default function FormInfoVeic (props) {
    const handleAddValuesToRequest = props.handleAddValuesToRequest;
    const requestToSend = props.requestToSend;
    const setTargaHandle = props.setTargaHandle;
    const setMarcaHandle = props.setMarcaHandle;
    const setModelloHandle = props.setModelloHandle;
    const setAlimentazioneHandle = props.setAlimentazioneHandle;
    const setValoreAssicuratoHandle = props.setValoreAssicuratoHandle;
    const setDataImmatricolazioneHandle= props.setDataImmatricolazioneHandle;
    const setDataAcquistoHandle= props.setDataAcquistoHandle;
    const setCodiceInfocarHandle= props.setCodiceInfocarHandle;

    return (
        
        <>
            <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Informazioni del Veicolo</FormLabel>
                <RadioGroup
                    row
                    style={{color:'white'}}
                    aria-labelledby="demo-row-radio-buttons-group-label"
                    name="row-radio-buttons-group"
                    value={requestToSend?.proprieta}
                    onChange={(e) => {handleAddValuesToRequest(e.target.value)}}
                >
                    <FormControlLabel value="1" control={<Radio />} label="E’ già di mia Proprieta’"/>
                    <FormControlLabel value="2" control={<Radio />} label="Devo Ancora acquistarlo ma conosco la Targa" />
                    <FormControlLabel value="3" control={<Radio />} label="Non la conosco ancora" />
                </RadioGroup>
            <br/>
            {(requestToSend?.proprieta==='1' || requestToSend?.proprieta==='2' ) && (
                <>  
                  <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Typography variant='h4' sx={{ mt: 2, mb: 1, py: 1 ,textAlign: 'center',color:"#447484"}}>
                        Inserire I Dati Del Veicolo
                    </Typography>
                    
                    <br/><br/>
                    <div>
                        <TextField value={requestToSend?.targa}  onChange={(e) => setTargaHandle(e.target.value?.toUpperCase())} color="primary" focused id="outlined-basic" label="Targa Veicolo" variant="outlined" helperText="Inserire La Targa" />
                        <TextField value={requestToSend?.marca}  onChange={(e) => setMarcaHandle(e.target.value)} color="primary" focused id="outlined-basic" label="Marca" variant="outlined" select helperText="Seleziona La Marca">
                            <MenuItem key={"option"} value={"FIAT"}>
                                FIAT
                            </MenuItem>
                            <MenuItem key={"option1"} value={"AUDI"}>
                                AUDI
                            </MenuItem>
                        </TextField>
                        <TextField value={requestToSend?.modello}  onChange={(e) => setModelloHandle(e.target.value)} helperText="Seleziona Il Modello" select focused color="primary"  id="outlined-basic" label="Modello" variant="outlined" >
                            <MenuItem key={"option"} value={"FIAT"}>
                                FIAT 1
                            </MenuItem>
                            <MenuItem key={"option1"} value={"AUDI"}>
                                AUDI
                            </MenuItem>
                        </TextField>
                        <TextField value={requestToSend?.alimentazione}  onChange={(e) => setAlimentazioneHandle(e.target.value)} helperText="Seleziona La Alimentazione" color="primary"  id="outlined-basic" select focused label="Alimentazione" variant="outlined" >
                            <MenuItem key={"BENZINA"} value={"BENZINA"}>
                                BENZINA
                            </MenuItem>
                            <MenuItem key={"DIESEL"} value={"DIESEL"}>
                                DIESEL
                            </MenuItem>
                            <MenuItem key={"ELETTRICA"} value={"ELETTRICA"}>
                                ELETTRICA
                            </MenuItem>
                            <MenuItem key={"GAS"} value={"GAS"}>
                                GAS
                            </MenuItem>
                        </TextField>
                    </div>
                    <br/>
                    <div>
                        <TextField value={requestToSend?.valoreassicurato}  onChange={(e) => setValoreAssicuratoHandle(e.target.value)} helperText="Valore Assicurato" color="primary" type="number" focused id="outlined-basic" label="Valore Assicurato Aggiornato" variant="outlined" />
                        <TextField value={requestToSend?.dataimmatricolazione}  date onChange={(e) => setDataImmatricolazioneHandle(e.target.value)} type="date" helperText="Data prima Immatricolazione" color="primary" focused id="outlined-basic" label="Data prima Immatricolazione" variant="outlined" />
                        <TextField value={requestToSend?.dataacquisto} date onChange={(e) => setDataAcquistoHandle(e.target.value)} type="date" helperText="Data Acquisto" color="primary" focused id="outlined-basic" label="Data Acquisto" variant="outlined" />
                        <TextField value={requestToSend?.codiceinfocar} onChange={(e) => setCodiceInfocarHandle(e.target.value)} helperText="Codice Infocar" color="primary" focused id="outlined-basic" label="Codice Infocar" variant="outlined" />
                    </div>
                    </Box>
                </> )}
            </FormControl>
        </>
      );
}
