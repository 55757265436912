import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import { Grid, Container, } from '@mui/material';

export default function MediaCard(props){
  const handleClickButton = props.handleClickButton;
  return (
    <Grid container spacing={12}>
        <Grid item xs={4}>
        <Card sx={{ 
                    maxWidth: 345,
                    boxShadow: 3,
                    textAlign: 'center',
                }}>
                <CardMedia
                    sx={{ height: 345 }}
                    image="https://oraclesbroker.it/wp-content/uploads/elementor/thumbs/icona-auto-pufjjq8gcoucs55o09fs8yy2x1bjylbi1oci8agwps.png"
                    title="AUTOMOBILE"
                />
                <CardContent style={{backgroundColor:"black"}}>
                    <Button onClick={() => handleClickButton("AUTOMOBILE",1)}  variant='outlined' color={"primary"}>AUTOMOBILE</Button>
                </CardContent>
                </Card>
        </Grid>
        <Grid item xs={4}>
        <Card sx={{ 
                    maxWidth: 345,
                    boxShadow: 3,
                    textAlign: 'center',
                }}>
                <CardMedia
                    sx={{ height: 345, }}
                    image="https://oraclesbroker.it/wp-content/uploads/elementor/thumbs/0.6-pokpi86tvztruxolgmbi10fbijwfx8hztt7qayd7z4.png"
                    title="MOTO"
                />
                <CardContent style={{backgroundColor:"black"}}>
                    <Button onClick={() => handleClickButton("MOTO",1)} variant='outlined' color="primary">MOTO</Button>
                </CardContent>
                </Card>
        </Grid>
        <Grid item xs={4}>
        <Card sx={{ 
                    maxWidth: 345,
                    boxShadow: 3,
                    textAlign: 'center',
                }}>
                <CardMedia
                    sx={{ height: 345 }}
                    image="https://oraclesbroker.it/wp-content/uploads/elementor/thumbs/VAN-ppxkqjqg9qzzhdyc6v3sjtkeaoybzuf87p0prcwjog.png"
                    title="VAN"
                />
                <CardContent style={{backgroundColor:"black"}}>
                    <Button onClick={() => handleClickButton("AUTOCARRO",1)} variant='outlined' color={"primary"}>AUTOCARRO</Button>
                </CardContent>
                </Card>
        </Grid>
    </Grid>
  );
}