import * as React from 'react';
import axios from 'axios';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';

import Buttons from './buttons';
import FormInfoVeic from './formInfoVeic';
import FormContraente from './contraenteForm';
import FormAssicurativo from './formAssicurativo';

const modalStyle = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
};

const steps = ['Cosa vuoi assicurare?', 'Dati Veicolo', 'Dati Contraente', 'Dati Assicurativi'];

export default function StepperPrev() {
  const [activeStep, setActiveStep] = React.useState(0);
  const [requestToSend,setRequestToSend]=React.useState({});
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const totalSteps = () => steps.length;
  const isLastStep = () => activeStep === totalSteps() - 1;

  const handleNext = () => {
    if(isLastStep()){
      const formData = new FormData()
      Object.keys(requestToSend).forEach((key) => {
        formData.append(key, requestToSend[key])
      })
      formData.append('key', 'q#FfXt#Uu188443p9D');
      console.log("DEBUG",formData);

      const axiosConfig = {
        headers: {
          'Content-Type': 'multipart/form-data,multipart/form-data',
          'Access-Control-Allow-Origin': '*'
        }
      };
      axios.post('https://preventivo.oraclesbroker.it/services/doRequest.php', formData, axiosConfig)
      .then((res) => {
        console.log("RESPONSE RECEIVED: ", res);
        setOpen(true);
      })
      .catch((err) => {
        console.log("AXIOS ERROR: ", err);
      })
    }else{
      setActiveStep(activeStep+1);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const handleClickButton = (type,step)  =>{
    setRequestToSend({'TypeRequest':type});
    setActiveStep(step);
  };

  const handleAddValuesPropToRequest = (value) => {
    const tmp = {...requestToSend,"proprieta":value};
    setRequestToSend(tmp);  
  };

  const setTargaHandle = (value) => {
    const tmp = {...requestToSend,"targa":value};
    setRequestToSend(tmp);
  };

  const setMarcaHandle = (value) => {
    const tmp = {...requestToSend,"marca":value};
    setRequestToSend(tmp);
  };

  const setModelloHandle = (value) => {
    const tmp = {...requestToSend,"modello":value};
    setRequestToSend(tmp);
  };

  const setAlimentazioneHandle = (value) => {
    const tmp = {...requestToSend,"alimentazione":value};
    setRequestToSend(tmp);
  };

  const setValoreAssicuratoHandle = (value) => {
    const tmp = {...requestToSend,"valoreassicurato":value};
    setRequestToSend(tmp);
  };

  const setDataImmatricolazioneHandle = (value) => {
    const tmp = {...requestToSend,"dataimmatricolazione":value};
    setRequestToSend(tmp);
  };

  const setDataAcquistoHandle = (value) => {
    const tmp = {...requestToSend,"dataacquisto":value};
    setRequestToSend(tmp);
  }; 

  const setCodiceInfocarHandle = (value) => {
    const tmp = {...requestToSend,"codiceinfocar":value};
    setRequestToSend(tmp);
  };

  const handleCodiceFiscale = (value) =>{
    const tmp = {...requestToSend,"codicefiscale":value};
    setRequestToSend(tmp);
  };

  const handleCAP = (value) =>{
    const tmp = {...requestToSend,"cap":value};
    setRequestToSend(tmp);
  };

  const handleNumeroAuto = (value) =>{
    const tmp = {...requestToSend,"numeroauto":value};
    setRequestToSend(tmp);
  };
  
  const handleTipoGuida = (value) =>{
    const tmp = {...requestToSend,"tipoguida":value};
    setRequestToSend(tmp);
  };

  const handleUtilizzo = (value) =>{
    const tmp = {...requestToSend,"utilizzo":value};
    setRequestToSend(tmp);
  };

  const handleTelefono = (value) =>{
    const tmp = {...requestToSend,"telefono":value};
    setRequestToSend(tmp);
  };

  const handleEmail = (value) =>{
    const tmp = {...requestToSend,"email":value};
    setRequestToSend(tmp);
  };

  const handleProvincia = (value) =>{
    const tmp = {...requestToSend,"provincia":value};
    setRequestToSend(tmp);
  };

  const handleNuovaAssicurazione= (value) =>{
    const tmp = {...requestToSend,"nuovassicurazione":value};
    setRequestToSend(tmp);
  };

  const handleDataDecorrenza= (value) =>{
    const tmp = {...requestToSend,"datadecorrenza":value};
    setRequestToSend(tmp);
};

const handleInfo= (value) =>{
    const tmp = {...requestToSend,"info":value};
    setRequestToSend(tmp);
};

const handleCompagniaAttuale= (value) =>{
    const tmp = {...requestToSend,"compagniaattuale":value};
    setRequestToSend(tmp);
};

const handleRca= (value) =>{
    const tmp = {...requestToSend,"rca":value};
    setRequestToSend(tmp);
};

const handleRcaMassimale= (value) =>{
    const tmp = {...requestToSend,"rcamassimale":value};
    setRequestToSend(tmp);
};

const handleTutelaLegale= (value) =>{
    const tmp = {...requestToSend,"tutelalegale":value};
    setRequestToSend(tmp);
};

const handleInfortuniConducente= (value) =>{
    const tmp = {...requestToSend,"infortuniconducente":value};
    setRequestToSend(tmp);
};

const handleFurtoIncendio= (value) =>{
    const tmp = {...requestToSend,"furtoincendio":value};
    setRequestToSend(tmp);
};

const handleCristalli= (value) =>{
    const tmp = {...requestToSend,"cristalli":value};
    setRequestToSend(tmp);
};

const handleEventiNaturali= (value) =>{
    const tmp = {...requestToSend,"eventinaturali":value};
    setRequestToSend(tmp);
};

const handleAttiVandalici= (value) =>{
    const tmp = {...requestToSend,"attivandalici":value};
    setRequestToSend(tmp);
};

const handleCollisione= (value) =>{
    const tmp = {...requestToSend,"collisione":value};
    setRequestToSend(tmp);
};

const handleKasko= (value) =>{
    const tmp = {...requestToSend,"kasko":value};
    setRequestToSend(tmp);
};

const handleRinunciaRivalsa= (value) =>{
    const tmp = {...requestToSend,"rinunciarivalsa":value};
    setRequestToSend(tmp);
};

const handleImprevisti= (value) =>{
    const tmp = {...requestToSend,"imprevisti":value};
    setRequestToSend(tmp);
};

const handleBonusProtetto= (value) =>{
    const tmp = {...requestToSend,"bonusprotetto":value};
    setRequestToSend(tmp);
};

const handleDispositivoSatellitare = (value) =>{
    const tmp = {...requestToSend,"dispositivosatellitare":value};
    setRequestToSend(tmp);
};

const handleRiparazione= (value) =>{
    const tmp = {...requestToSend,"riparazione":value};
    setRequestToSend(tmp);
};

const handleSospensione= (value) =>{
    const tmp = {...requestToSend,"sospensione":value};
    setRequestToSend(tmp);
};

const handleConsenso= (value) =>{
    const tmp = {...requestToSend,"consenso":value};
    setRequestToSend(tmp);
};

  return (
    <Box style={{textAlign:"center"}} sx={{ width: '100%',textAlign: "center" }}>
      <Stepper nonLinear activeStep={activeStep}>
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton  variant="contained" onClick={handleStep(index)}>
              {label}
            </StepButton>
          </Step>
        ))}
      </Stepper>
      <div>
          <div>
           <br/>
            <Typography variant='h4' sx={{ mt: 2, mb: 1, py: 1 ,textAlign: 'center',color:"#447484"}}> 
              {activeStep===0 && ("Cosa vuoi assicurare ?")}
              {activeStep===1 && ("Dati Del Veicolo")}
              {activeStep===2 && ("Dati Del Contraente")}
              {activeStep===3 && ("Dati Assicurativi")}
            </Typography>
            <br/>

            {/* Qui vanno gli step */}
            {activeStep===0&&(
              <Buttons handleClickButton={handleClickButton} />
            )}
            {activeStep===1&&(
              <FormInfoVeic setCodiceInfocarHandle={setCodiceInfocarHandle} setDataAcquistoHandle={setDataAcquistoHandle} setDataImmatricolazioneHandle={setDataImmatricolazioneHandle} setValoreAssicuratoHandle={setValoreAssicuratoHandle} setAlimentazioneHandle={setAlimentazioneHandle} setModelloHandle={setModelloHandle} setMarcaHandle={setMarcaHandle} setTargaHandle={setTargaHandle} requestToSend={requestToSend} handleAddValuesToRequest={handleAddValuesPropToRequest} />
            )}
            {activeStep===2&&(
              <FormContraente requestToSend={requestToSend} handleProvincia={handleProvincia} handleCAP={handleCAP} handleNumeroAuto={handleNumeroAuto} handleTipoGuida={handleTipoGuida} handleUtilizzo={handleUtilizzo} handleTelefono={handleTelefono} handleEmail={handleEmail} handleCodiceFiscale={handleCodiceFiscale}/>
            )}

            {activeStep===3&&(
              <FormAssicurativo handleDataDecorrenza={handleDataDecorrenza} handleInfo={handleInfo} handleCompagniaAttuale={handleCompagniaAttuale} handleRca={handleRca} handleRcaMassimale={handleRcaMassimale} handleTutelaLegale={handleTutelaLegale} handleInfortuniConducente={handleInfortuniConducente} handleFurtoIncendio={handleFurtoIncendio} handleCristalli={handleCristalli} handleEventiNaturali={handleEventiNaturali} handleAttiVandalici={handleAttiVandalici} handleCollisione={handleCollisione} handleKasko={handleKasko} handleRinunciaRivalsa={handleRinunciaRivalsa} handleImprevisti={handleImprevisti} handleBonusProtetto={handleBonusProtetto} handleDispositivoSatellitare={handleDispositivoSatellitare} handleRiparazione={handleRiparazione} handleSospensione={handleSospensione} handleConsenso={handleConsenso} handleNuovaAssicurazione={handleNuovaAssicurazione} requestToSend={requestToSend} />
            )}

            <br/>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
              <Button
                variant="contained"
                disabled={activeStep === 0}
                onClick={handleBack}
                sx={{ mr: 1 }}
              >
                Indietro
              </Button>
              <Box sx={{ flex: '1 1 auto' }} />
             {activeStep!==0 &&(
                <Button variant="contained"
                onClick={handleNext}
                sx={{ mr: 1 }}>
                 {activeStep===3 ? "Invia" : "Avanti"}
               </Button>
             )} 
            </Box>
          </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Richiesta Inviata
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            La richiesta è stata inviata correttamente, verrete ricontattati il prima possibile.
          </Typography>
          <br/>
          <Button onClick={()=> setOpen(false)} variant="contained" >Chiudi</Button>
        </Box>
      </Modal>
    </Box>
  );
}