import * as React from 'react';
import {FormControl,FormLabel,RadioGroup,FormControlLabel,Radio,TextField,Typography,Box,MenuItem} from '@mui/material';

import province from '../../../utils/province-italia.json';

export default function contraenteForm (props) {
    const requestToSend = props.requestToSend;
    const handleCodiceFiscale = props.handleCodiceFiscale;
    const handleCAP = props.handleCAP;
    const handleNumeroAuto = props.handleNumeroAuto;
    const handleTipoGuida = props.handleTipoGuida;
    const handleUtilizzo = props.handleUtilizzo;
    const handleTelefono = props.handleTelefono;
    const handleEmail = props.handleEmail;
    const handleProvincia = props.handleProvincia;

    return (
        <>
            <FormControl>
            <FormLabel id="demo-row-radio-buttons-group-label">Informazioni Del Contraente</FormLabel>
            <Box
                    component="form"
                    sx={{
                        '& .MuiTextField-root': { m: 1, width: '25ch' },
                    }}
                    noValidate
                    autoComplete="off"
                    >
                    <Typography variant='h4' sx={{ mt: 2, mb: 1, py: 1 ,textAlign: 'center',color:"#447484"}}>
                        Inserire I Dati Del Contraente
                    </Typography>
                    <br/><br/>
                    <div>
                        <TextField onChange={(e) => {handleCodiceFiscale(e.target.value)}} value={requestToSend?.codicefiscale} color="primary" focused id="outlined-basic" label="Codice Fiscale/P.Iva" variant="outlined" helperText="Codice Fiscale/P.Iva" />
                        <TextField onChange={(e) => {handleProvincia(e.target.value)}} value={requestToSend?.provincia} color="primary" select focused id="outlined-basic" label="Provincia di Residenza" variant="outlined" helperText="Provincia di Residenza" >
                            {province.map((provincia) =>
                                <MenuItem key={provincia?.sigla} value={provincia?.sigla}>
                                    {provincia?.sigla}
                                </MenuItem>
                            )}                            
                        </TextField>
                        <TextField onChange={(e) => {handleCAP(e.target.value)}} value={requestToSend?.cap} color="primary" focused id="outlined-basic" label="CAP" variant="outlined" helperText="CAP" />
                        <TextField onChange={(e) => {handleNumeroAuto(e.target.value)}} value={requestToSend?.numeroauto}  color="primary" focused id="outlined-basic" label="Altre auto nel nucleo familiare" variant="outlined" helperText="Altre auto nel nucleo familiare" />
                    </div>
                    <div>
                        <TextField onChange={(e) => {handleTipoGuida(e.target.value)}} value={requestToSend?.tipoguida} select color="primary" focused id="outlined-basic" label="Tipo di Guida" variant="outlined" helperText="Tipo di Guida" >
                            <MenuItem key={"Esclusiva"} value={"Esclusiva"}>
                                Esclusiva
                            </MenuItem>
                            <MenuItem key={"Esperta"} value={"Esperta"}>
                                Esperta
                            </MenuItem>
                            <MenuItem key={"Libera"} value={"Libera"}>
                                Libera
                            </MenuItem>
                        </TextField>
                        <TextField onChange={(e) => {handleUtilizzo(e.target.value)}} value={requestToSend?.utilizzo} select color="primary" focused id="outlined-basic" label="Utilizzo" variant="outlined" helperText="Utilizzo">
                            <MenuItem key={"lavoro"} value={"lavoro"}>
                                Lavoro
                            </MenuItem>
                            <MenuItem key={"libero"} value={"libero"}>
                                Tempo Libero
                            </MenuItem>
                        </TextField>
                        <TextField onChange={(e) => {handleTelefono(e.target.value)}} value={requestToSend?.telefono} color="primary" focused id="outlined-basic" label="Telefono" variant="outlined" helperText="Telefono" />
                        <TextField onChange={(e) => {handleEmail(e.target.value)}} value={requestToSend?.email} color="primary" focused id="outlined-basic" label="Email" variant="outlined" helperText="Email" />
                    </div>
            </Box>
            </FormControl>
        </>
      );
}
